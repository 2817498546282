import { InfoIcon } from '@trawa-energy/ui-kit';
import { useIntl } from 'react-intl';
import { config } from '../config';

export function MaintenanceBanner() {
    const intl = useIntl();
    return (
        <div className="banner-info py-2 px-4 mb-4 flex gap-3 rounded-xl">
            <InfoIcon className="w-10 h-10" />
            <p>
                {intl.formatMessage(
                    {
                        id: 'maintenanceBanner.body',
                    },
                    {
                        dayAndMonth: config.maintenanceDayAndMonth,
                        startTime: config.maintenanceStartTime,
                        endTime: config.maintenanceEndTime,
                    },
                )}
            </p>
        </div>
    );
}
