import ReactDOM from 'react-dom/client';
import './index.css';
import { initSentry } from './utils/setupSentry.ts';
import { PasswordlessContextProvider } from 'amazon-cognito-passwordless-auth/react';
import { App } from './App.tsx';
import { initFeaturevisor } from './utils/setupFeaturevisor.ts';
import { FeaturevisorProvider } from '@featurevisor/react';

initSentry();
const featurevisorInstance = initFeaturevisor();

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement!).render(
    <PasswordlessContextProvider enableLocalUserCache={true}>
        <FeaturevisorProvider instance={featurevisorInstance}>
            <App />
        </FeaturevisorProvider>
    </PasswordlessContextProvider>,
);
