import React from 'react';
import { createBrowserRouter, Navigate, useParams, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AuthenticatedLayout } from './AuthenticatedLayout.tsx';
import { Login } from '../pages/login/Login.tsx';
import { ContractSelection } from '../pages/ContractSelection.tsx';
import { Products } from '../pages/Products.tsx';
import { Overview } from '../pages/Overview.tsx';
import { ConsumptionView } from '../pages/ConsumptionView.tsx';
import { Documents } from '../pages/Documents.tsx';
import { Locations } from '../pages/Locations.tsx';
import { USER } from '../userSettings.ts';
import { Root } from './Root.tsx';
import { CostPrediction } from '../pages/CostPrediction.tsx';
import { ExchangePrices } from '../pages/ExchangePrices.tsx';
import { SignUpConfirmation } from '../pages/auth/SignUpConfirmation.tsx';
import { Account } from '../pages/Account.tsx';
import { MarketLocationsList } from '../pages/MarketLocationsList.tsx';
import { Analysis } from '../pages/Analysis.tsx';
import { TvScreen } from '../pages/tv';
import NotFoundPage from '../pages/NotFoundPage.tsx';
import { AnalysisDemoCharts } from '../components/AnalysisDemoCharts.tsx';
import { AuthenticationGuard } from './AuthenticationGuard.tsx';
import { MagicLinkLogin } from '../pages/login/magic-link/MagicLinkLogin.tsx';
import MaintenancePage from '../pages/MaintenancePage.tsx';
import { config } from '../config.ts';

export const loginPath = '/login';
export const contractSelectionPath = '/contract-selection';
export const authenticatedDefault = '/';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const isLocalhost = window.location.hostname === 'localhost';
const errorElement = isLocalhost ? <>Error in the router!</> : <NotFoundPage />;

// eslint-disable-next-line react-refresh/only-export-components
const ChooseLogin = () => {
    const [searchParams] = useSearchParams();
    const accountId = searchParams.get('accountId'); // if accountId is present, then we are on legacy login page
    return accountId ? <Login /> : <MagicLinkLogin />;
};

export const router = sentryCreateBrowserRouter([
    {
        path: '/',
        element: !config.isUnderMaintenance ? <Root /> : <MaintenancePage />,
        errorElement,
        children: [
            {
                path: loginPath,
                element: (
                    <React.StrictMode>
                        <ChooseLogin />
                    </React.StrictMode>
                ),
            },
            {
                path: 'auth',
                children: [
                    {
                        path: 'confirm',
                        element: (
                            <React.StrictMode>
                                <SignUpConfirmation />
                            </React.StrictMode>
                        ),
                    },
                ],
            },
            {
                element: <AuthenticationGuard />,
                children: [
                    {
                        path: contractSelectionPath,
                        element: <ContractSelection />,
                    },
                    {
                        element: <AuthenticatedLayout />,
                        errorElement: <>TODO: error!</>, // seems like we don't really see these errors often, need to investigate why this is needed?
                        children: [
                            {
                                index: true,
                                path: '',
                                element: (
                                    <React.StrictMode>
                                        <Navigate to="/overview" />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'consumption',
                                element: (
                                    <React.StrictMode>
                                        <ConsumptionView />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'account',
                                element: (
                                    <React.StrictMode>
                                        <Account />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'overview',
                                element: (
                                    <React.StrictMode>
                                        <Overview />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'products',
                                element: (
                                    <React.StrictMode>
                                        <Products />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'cost-prediction',
                                element: (
                                    <React.StrictMode>
                                        <CostPrediction />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'documents',
                                element: (
                                    <React.StrictMode>
                                        <Documents />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'exchange-prices',
                                element: (
                                    <React.StrictMode>
                                        <ExchangePrices />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'locations',
                                element: <Locations />,
                            },
                            {
                                path: 'market-locations',
                                element: (
                                    <React.StrictMode>
                                        <MarketLocationsList />
                                    </React.StrictMode>
                                ),
                            },
                            {
                                path: 'analysis',
                                element: (
                                    <React.StrictMode>
                                        <Analysis />
                                    </React.StrictMode>
                                ),
                            },
                            /*
                                This route is just for demo purposes and has no corosponding nav link.
                                Ask Volker if this is still needed. Maybe it can be removed.
                            */
                            {
                                path: 'analysis-demo',
                                element: (
                                    <React.StrictMode>
                                        <AnalysisDemoCharts />
                                    </React.StrictMode>
                                ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'public/:accountId',
        element: (
            <AccountIdGuard allowedAccountId={USER.ACTIVE_TV}>
                <TvScreen />
            </AccountIdGuard>
        ),
        errorElement,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
]);

// eslint-disable-next-line react-refresh/only-export-components
function AccountIdGuard({ children, allowedAccountId }: { children: React.ReactNode; allowedAccountId: string }) {
    const { accountId } = useParams();

    if (accountId !== allowedAccountId) {
        throw Error('Account id does not match the allowed accountId');
    }

    return children;
}
