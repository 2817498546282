import { useUserSettings } from '../userSettings.ts';
import { useFlag, useStatus } from '@featurevisor/react';
import { useMemo } from 'react';

export const useFeatureFlags = () => {
    const { userSettings } = useUserSettings();
    const { isReady } = useStatus();
    const context = useMemo(() => ({ accountId: userSettings.accountId }), [userSettings.accountId]);

    const isClusterEnabled = useFlag('portalClusterView', context);
    const isManageOnly = useFlag('manageCustomer', context);
    const isDemo = useFlag('demoCustomer', context);

    return useMemo(
        () => ({
            isBeta: isDemo,
            isManageOnly,
            isClusterEnabled,
            isReady,
        }),
        [isDemo, isManageOnly, isClusterEnabled, isReady],
    );
};
